<script setup lang="ts">
import {defaults} from "~/types/FormFieldProps"
import formFieldExpose from "~/components/atoms/form/form-field-expose"

interface Option {
  label: string,
  value: any,
  disabled?: boolean,
}

interface Props {
  // This is common with other fields
  name?: string,
  label?: string,
  modelValue?: string,
  placeholder?: string,
  autofocus?: boolean,
  required?: boolean,
  error?: string,

  labelClass?: any,
  inputClass?: any,

  // This is specific
  options: Option[],
  expanded?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
  ...defaults,
  expanded: false,
})

const {inputClass: baseInputClass} = useFormItem()

const input = ref<HTMLInputElement | null>(null)

defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

const computedOptions = computed(() => {
  return [{label: '', value: ''}, ...props.options]
})

defineExpose(formFieldExpose(input, props))
</script>

<template>
  <component :is="expanded ? 'div' : 'label'" class="block">
    <AtomsFormLabel :text="label" :required="required" />
    <div v-if="expanded" class="pt-2">
      <label v-for="option in options" class="flex items-start gap-2 ml-2 mb-2 text-button-small">
        <input type="radio"
               :name="name"
               :value="option.value"
               :disabled="option.disabled"
               :checked="modelValue == option.value"
               @input="$emit('update:modelValue', $event.target.value)"
               class="mt-0.5 accent-domain">
        <span>{{ option.label }}</span>
      </label>
    </div>
    <template v-else>
      <select ref="input"
              :name="name"
              :class="[baseInputClass, inputClass, error ? '!border-red' : '']"
              :autofocus="autofocus"
              :required="required"
              :value="modelValue" @input="$emit('update:modelValue', $event.target.value)">
        <option v-for="option in computedOptions"
                :value="option.value"
                :key="option.value"
                :disabled="option.disabled"
                :selected="modelValue == option.value">{{ option.label }}</option>
      </select>
    </template>
    <AtomsFormFieldError :text="error" />
    <slot name="after-input"></slot>
  </component>
</template>